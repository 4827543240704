<template>
    <Content :links="links" :filter="true" :showFilterDefault="true">
        <template v-slot:topbar>

            <div class="flex h-full items-center">
                <template v-if="this.$userCan('manage_invoices')">
                    <router-link to="/invoices/create"
                                 class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100"
                                 @click="showAddInvoiceView">
                        <PlusIcon class="w-7 h-7"/>
                    </router-link>
                </template>
            </div>
        </template>

        <template v-slot:filter>
            <div class="flex flex-wrap items-center">
                <div class="flex flex-wrap sm:flex-nowrap items-center mr-3  ">
                    <div class="w-48">
                        <Input type="date" v-model="filter.from" @change="getItems"/>
                    </div>
                    <span class="p-1">-</span>
                    <div class="w-48">
                        <Input type="date" v-model="filter.to" @change="getItems"/>
                    </div>
                </div>

                <div class="w-48 mr-3">
                    <Select v-model="filter.status" :items="statuses" placeholder="Status" @click="getItems"
                            :clearAble="false" :showPlaceholder="false"/>
                </div>
                <div class="w-48 mr-3">
                    <Select v-model="filter.type" :items="types" placeholder="Veids" @click="getItems" :clearAble="true"
                            :showPlaceholder="false"/>
                </div>

                <div class="mt-2 sm:mt-1">
                    <button @click="downloadExcel" type="button"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-600 bg-blue-600 bg-opacity-10 hover:bg-opacity-30 hover:text-blue-600 focus:outline-none focus:ring-0 transition duration-300 ease-in-out">
                        Rēķinu Excel
                    </button>
                </div>
            </div>
        </template>

        <template v-slot:content>
            <div>
                <template v-if="reports && reports.customers.length > 0">

                    <div class="sm:rounded-md mb-3">
                        <ul role="list" class="">
                            <div class="bg-white rounded-lg shadow-lg mb-10 -mt-6">
                                <item-card>
                                    <template v-slot:title>
                                        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                                            <template v-if="filter.status && filter.status.name">
                                                {{ filter.status.name }}
                                            </template>
                                            <template v-else>
                                                Kopā
                                            </template>
                                            par izvēlēto periodu
                                            <template v-if="filter.type && filter.type.name">
                                                - {{ filter.type.name }}
                                            </template>
                                        </h3>
                                    </template>
                                    <template v-slot:content>
                                        <item-text title="Kopā EUR" :text="reports.totals.total"
                                                   textClass="font-semibold"/>
                                        <item-text title="PVN EUR" :text="reports.totals.vat"
                                                   textClass="font-semibold"/>
                                        <item-text title="Kapā ar PVN EUR" :text="reports.totals.total_with_vat"
                                                   textClass="font-semibold"/>
                                    </template>
                                </item-card>
                            </div>


                            <template v-for="report in reports.customers" :key="report.id">
                                <ShowInvoiceReport :report="report" :type="filter.type"/>
                            </template>
                        </ul>
                    </div>

                    <!-- <Pagination :meta="reports.meta" :onPageChange="onPageChange" /> -->
                </template>

                <template v-else>
                    <p class="py-6 text-center dark:text-gray-300">
                        Diemžēl nekas netika atrasts
                    </p>
                </template>
            </div>
        </template>
    </Content>
</template>

<script>
import Search from "@/services/Helpers/search";
import {mapGetters} from "vuex";
import {debounce} from 'vue-debounce'
import ShowInvoiceReport from "@/components/Invoices/Reports/ShowInvoiceReport"
import invoicesLinks from "../../library/appbar/invoicesLinks";
import Select from "@/components/Components/Select"
import Input from "@/components/Components/Input"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Dates from "@/services/Helpers/dates";
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
    name: "InvoicesReports",
    components: {
        ShowInvoiceReport,
        Select,
        Input,
        ItemCard,
        ItemText,
        PlusIcon,
    },
    data: () => ({
        showFiltersTab: true,
        isLoading: false,
        search: '',
        links: invoicesLinks,
        filter: {
            from: Dates.oneMonthAgo(),
            to: new Date().toISOString().substr(0, 10),
            status: 'uninvoiced',
            type: '',
        },
        statuses: [
            {value: 'invoiced', name: 'Izrakstīts'},
            {value: 'uninvoiced', name: 'Neizrakstīts'},
        ],
        types: [
            {value: 'orders', name: 'Pasūtījumi'},
            {value: 'services', name: 'Serviss'},
            {value: 'rent', name: 'Noma'},
        ],
        queryParams: null,
    }),
    created() {
        this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if (Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    from: this.filter.from,
                    to: this.filter.to,
                    status: this.filter.status,
                    type: '',
                    page: Search.getCurrentPage(),
                    q: this.search
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()

        this.searchInvoices()
    },
    computed: {
        ...mapGetters({
            reports: 'reports',
            formsForDisplay: 'formsForDisplay',
            user: 'user',
        })
    },
    watch: {
        search: function () {
            this.searchInvoices()
        },
    },
    methods: {
        getItems() {
            this.$router.replace({
                query: {
                    from: this.filter.from,
                    to: this.filter.to,
                    status: this.filter.status ? this.filter.status.value : 'uninvoiced',
                    type: this.filter.type ? this.filter.type.value : '',
                    page: 1,
                    q: this.queryParams.q
                }
            })

            this.queryParams = this.$route.query
            this.searchInvoices()
        },

        setFiltersFromParams() {
            this.filter.status = this.statuses.find(status => {
                return status.value == this.queryParams.status
            })
            this.filter.type = this.types.find(type => {
                return type.value == this.queryParams.type
            })

            this.filter.from = this.queryParams.from ? this.queryParams.from : this.filter.from
            this.filter.to = this.queryParams.to ? this.queryParams.to : this.filter.to
        },
        searchInvoices: debounce(function () {
            this.$store.dispatch('getReports', this.queryParams)
        }, 500),
        onPageChange(page) {
            this.searchInvoices()
        },
        showAddInvoiceView() {
            this.$router.push({path: '/invoices/create'})
        },
        downloadExcel() {
            this.$store.dispatch('getInvoicesExcelReport',{
                from: this.filter.from,
                to: this.filter.to
            })
        }
    }
}
</script>

<style>

</style>