<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                {{report.customer}}
            </h3>
        </template>

        <template v-slot:buttons>
          <div class="flex gap-3">
            <template v-if="type == ''">
              <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
            </template>
          </div>
        </template>

        <template v-slot:content>

            <item-text title="Kopā EUR" :text="report.totals.total.total" />
            <item-text title="PVN EUR" :text="report.totals.total.vat" />
            <item-text title="Summa EUR" :text="report.totals.total.total_with_vat" />

        </template>

        <template v-slot:additional>
          <template v-if="showFullList && type == ''">
            <div class="w-full -mt-3 pb-2">
              <template v-if="report.totals.orders.total > 0">
                <div class="w-full grid grid-cols-12 px-2 py-1">
                  <div class="col-span-12 sm:col-span-6 lg:col-span-1">
                    <h3 class="font-semibold">
                      Pasūtījumi
                    </h3>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Kopā EUR :</span> {{report.totals.orders.total}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">PVN EUR:</span> {{report.totals.orders.vat}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Summa EUR:</span> {{report.totals.orders.total_with_vat}}</p>
                  </div>
                </div>
              </template>

              <template v-if="report.totals.services.total > 0">
                <div class="w-full grid grid-cols-12 px-2 py-1">
                  <div class="col-span-12 sm:col-span-6 lg:col-span-1">
                    <h3 class="font-semibold">
                      Serviss
                    </h3>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Kopā EUR :</span> {{report.totals.services.total}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">PVN EUR:</span> {{report.totals.services.vat}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Summa EUR:</span> {{report.totals.services.total_with_vat}}</p>
                  </div>
                </div>
              </template>

              <template v-if="report.totals.rent.total > 0">
                <div class="w-full grid grid-cols-12 px-2 py-1">
                  <div class="col-span-12 sm:col-span-6 lg:col-span-1">
                    <h3 class="font-semibold">
                      Noma
                    </h3>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Kopā EUR :</span> {{report.totals.rent.total}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">PVN EUR:</span> {{report.totals.rent.vat}}</p>
                  </div>
                  <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <p class="items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0"><span class="text-sm text-gray-500 dark:text-gray-400">Summa EUR:</span> {{report.totals.rent.total_with_vat}}</p>
                  </div>
                </div>
              </template>

            </div>
          </template>
        </template>
    </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowInvoiceReport",
  components: {
    ItemCard,
    ItemText,
  },
  data() {
    return {
      showFullList: false,
    }
  },
  computed: {
    ...mapGetters({
        loading: 'loading',
    })
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      default: ''
    }
  },
  methods: {
    userHavePermission(permission){
      return this.$userCan(permission)
    },
  }
}
</script>
